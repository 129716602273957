import { ErrorHandler, Injectable, inject, isDevMode } from '@angular/core';
import { type BrowserClient, browserTracingIntegration, init, replayIntegration } from '@sentry/browser';

import { ENV } from '@trtl/env';

@Injectable()
export class SentryHandler implements ErrorHandler {

  private readonly env = inject(ENV);

  private readonly client?: BrowserClient;

  constructor() {
    if (!isDevMode()) {
      this.client = init({
        environment: this.env.environment,
        dsn: this.env.sentry.dsn,
        replaysSessionSampleRate: this.env.sentry.replaysSessionSampleRate,
        replaysOnErrorSampleRate: this.env.sentry.replaysOnErrorSampleRate,
        tracePropagationTargets: this.env.sentry.tracePropagationTargets,
        integrations: [
          browserTracingIntegration(),
          replayIntegration(),
        ],
        tracesSampleRate: 1.0,
      }) as BrowserClient;
    }
  }

  handleError(error: unknown): void {
    !isDevMode() && this.client?.captureException(error);
    // eslint-disable-next-line no-console
    console.error(error);
  }
}
