import { enableProdMode, isDevMode } from '@angular/core';
import { bootstrapApplication, enableDebugTools } from '@angular/platform-browser';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

environment.production && enableProdMode();

setTimeout(() => {
  bootstrapApplication(AppComponent, appConfig)
  .then((m) => {
    isDevMode() && enableDebugTools(m.components[0]);
  })
  .catch(err =>
    // eslint-disable-next-line no-console
    console.error(err)
  );
});
