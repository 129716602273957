import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { Tokens } from '@trtl/auth';
import { ENV } from '@trtl/env';

@Injectable({ providedIn: 'root' })
export class TenantApiService {

  private readonly env = inject(ENV);
  private readonly http = inject(HttpClient);

  public refreshToken(refreshToken: string) {
    return this.http.post<Tokens>(
      `${this.env.api}/api/v1/auth/refresh`,
      {
        refreshToken,
      },
    );
  }
}
