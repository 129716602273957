import { HttpHandlerFn, HttpRequest } from '@angular/common/http';

export function roleInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn) {
  if (!localStorage.getItem('tokens')) {
    return next(req);
  }

  const tokens = JSON.parse(localStorage.getItem('tokens')!);
  const idToken = JSON.parse(atob(tokens.idToken.split('.')[1]));
  const role = idToken['custom:role'];

  return next(req.clone({
    headers: req.headers.append('x-role', role),
  }));
}
