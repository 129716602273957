import { HttpErrorResponse, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { catchError, from, switchMap, throwError } from 'rxjs';

import { AuthState } from '@trtl/auth';

import { TenantService } from '../services';

export function authInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn) {
  const store = inject(Store);
  const router = inject(Router);
  const tenantService = inject(TenantService);
  const accessToken = store.selectSignal(AuthState.accessToken);

  return next(req.clone({
    headers: req.headers.set('Authorization', `Bearer ${accessToken()}`),
  })).pipe(
    catchError((error: HttpErrorResponse) => {
      switch (error.status) {
        case 401:
        case 403:
          return tenantService.refreshToken(store.selectSnapshot(AuthState.refreshToken)!).pipe(
            switchMap(() => next(req.clone({
              headers: req.headers.set('Authorization', `Bearer ${accessToken()}`),
            }))),
            catchError(err => from(router.navigate(['/auth/logout'])).pipe(
              switchMap(() => throwError(() => err)),
            )),
          );
      }

      return throwError(() => error);
    }),
  );
}
