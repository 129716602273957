import { inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { catchError, switchMap, throwError } from 'rxjs';

import { SetTokens } from '@trtl/auth';

import { TenantApiService } from './tenant-api.service';

@Injectable({ providedIn: 'root' })
export class TenantService {

  private readonly store = inject(Store);
  private readonly api = inject(TenantApiService);

  public refreshToken(refreshToken: string) {
    return this.api.refreshToken(refreshToken).pipe(
      switchMap(tokens => this.store.dispatch(new SetTokens(tokens))),
      catchError((err) => {
        this.store.dispatch(new SetTokens(null));

        return throwError(() => err);
      }),
    );
  }
}
